<script setup lang="ts">
import SlideUpDown from "vue-slide-up-down";

const mainSlot = ref<HTMLElement>();
const showItems = defineModel<any>();
const uid = getCurrentInstance()?.uid;

let elements: HTMLDivElement[] = [];

onMounted(() => addControls());

onUnmounted(() => removeControls());

/**
 * Toggle accordion item visibility
 */
const toggleAccordion = () => {
  showItems.value = !showItems.value;
};

/**
 * Add event listeners to all .clickable classes inside the main slot
 */
const addControls = () => {
  const check = mainSlot.value;
  elements = Array.from(check?.querySelectorAll("* > .clickable") || []);
  elements?.forEach((element) => {
    element.setAttribute('aria-controls', `accordion-items-${uid}`)
    element.addEventListener("click", event => {
      event.preventDefault()
      toggleAccordion()
    });
    element.addEventListener("keydown", event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        toggleAccordion()
      }
    })
  });
};

/**
 * Remove all event listeners
 */
const removeControls = () => {
  elements?.forEach((element) => {
    element.removeEventListener("click", toggleAccordion);
  });
};

defineComponent({
  name: "Accordion",
});

defineExpose({
  toggleAccordion,
});
</script>

<template>
  <div>
    <div ref="mainSlot" class="text-sm flex items-center pt-0.5 h-full">
      <slot />
    </div>

    <SlideUpDown :active="showItems" class="animate-collapse" :id="`accordion-items-${uid}`">
      <div v-if="showItems" class="max-h-max">
        <slot name="items" />
      </div>
    </SlideUpDown>
  </div>
</template>

<style scoped></style>
